import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MessageLayout from '../components/layout/Message'
import { getUserBanEndTime } from '../services/userService'
import { MAIN_PAGE } from '../constants'
import { handleError } from '../utils/utils'

/**
 * A page to display the information about unblocking for blocked users
 *
 * @component
 * @returns {ReactNode} A React element that renders the information about and of blocking
 */
const BlockedAccount = () => {
  const navigate = useNavigate()
  const [banEndTime, setBanEndTime] = useState('')

  useEffect(() => {
    getUserBanEndTime()
      .then((data) => {
        if (!data.isBlocked) {
          navigate(MAIN_PAGE)
          return
        }
        setBanEndTime(data.banEndTime)
      })
      .catch(handleError)
  }, [])

  return (
    <MessageLayout
      title={`Your account is currently blocked! Your account will be available after ${banEndTime}`}
    >
      Note: account may be unbanned with 5 minutes delay
    </MessageLayout>
  )
}

export default BlockedAccount
