import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'

import AuthContext from '../../../../context/AuthContext'
import PriceText from '../../../../components/giftCards/PriceText'
import { formatNumber } from '../../../../utils/utils'
import { CURRENCY_SYMBOL, PIGGY_BANKS_MULTIPLIER, POINTS_MULTIPLIER } from '../../../../constants'

import './index.css'

const GiftCard = ({ giftCard, denomination }) => {
  const { user } = useContext(AuthContext)

  const pointsValue = POINTS_MULTIPLIER(denomination)
  const piggyBanksValue = PIGGY_BANKS_MULTIPLIER(denomination)
  const formattedPointsValue = formatNumber(pointsValue)
  const formattedPiggiesValue = formatNumber(piggyBanksValue)

  return (
    <div className="giftCardListCardInner">
      <Card className="giftCardListCard" sx={styles.card} data-denomination={denomination}>
        <CardMedia component="img" sx={styles.cardMedia} src={giftCard.image} />
        <CardContent sx={styles.cardContent}>
          <Typography variant="h3" sx={styles.currency}>
            {CURRENCY_SYMBOL[giftCard?.currency]}
            {denomination} {giftCard?.currency}
          </Typography>
          <div className="giftCardListCardCurrency">
            <PriceText
              userBalance={user?.piggyBanks}
              giftCardValue={piggyBanksValue}
              value={formattedPiggiesValue}
              isPig={true}
            />
            <PriceText
              userBalance={user?.points}
              giftCardValue={pointsValue}
              value={formattedPointsValue}
              isPig={false}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

const styles = {
  card: {
    width: '216px',
    borderRadius: '12px',
    transition: 'none',
    boxShadow: '0px 12px 16px -4px #0014311F',
    cursor: 'pointer',
  },
  cardMedia: {
    height: '136px',
  },
  cardContent: {
    padding: '8px 12px',
    backgroundColor: '#fff',
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  currency: {
    color: '#18181B',
  },
}

GiftCard.propTypes = {
  giftCard: PropTypes.object.isRequired,
  denomination: PropTypes.number.isRequired,
}

export default GiftCard
