import React, { useContext, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import toast from 'react-hot-toast'
import { QUEST_TYPE } from '../../../../../constants'
import AuthContext from '../../../../../context/AuthContext'
import { createVirtualCurrencyImagesArrays } from '../../../../../utils/utils'
import { handleHeaderCounter, setHeaderCount } from '../../../../../utils/animations'
import { claimQuestReward } from '../../../../../services/questsService'
import OfferAlert from '../../../../../components/offerAlert'
import { getUserById } from '../../../../../services/userService'

const QuestAlert = ({ quest, type }) => {
  const navigate = useNavigate()
  const { user, updateUserData } = useContext(AuthContext)
  const isClaimable = type === QUEST_TYPE.COMPLETED
  const buttonText = useMemo(() => {
    if (isClaimable) {
      return 'Claim!'
    } else if (quest.isNotificationCTAShown) {
      return 'Explore Quest'
    }
  }, [isClaimable, quest.isNotificationCTAShown])
  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const handleClaim = (sourceDiv) => {
    createVirtualCurrencyImagesArrays(
      sourceDiv,
      quest.coinsReward,
      quest.piggyReward,
      setCoinsArray,
      setPigsArray,
      0.5
    )

    setTimeout(() => {
      handleHeaderCounter(false, quest.coinsReward)
      handleHeaderCounter(true, quest.piggyReward)
    }, 500)

    setTimeout(() => {
      setSwiping(true)
      setTimeout(async () => {
        setHidden(true)
        try {
          await claimQuestReward(quest.id)
        } catch (error) {
          if (
            error.response.data.message ===
            'Error with quest claim transaction: QUEST_ERROR: Reward for this quest is already claimed!'
          ) {
            toast.error('Reward for this quest is already claimed!')
          }
        }
        try {
          const updatedUser = await getUserById(user.id)
          updateUserData(updatedUser)
          setHeaderCount(updatedUser.piggyBanks, updatedUser.points)
        } catch (error) {
          toast.error('Unable to fetch user details.')
        }
      }, 1000)
    }, 1800)
  }

  return (
    <>
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>
      <OfferAlert
        icon={<img src={quest.imageUrl} />}
        title={quest.title}
        handleClick={
          buttonText === 'Explore Quest'
            ? () => navigate('/quests')
            : (event) => handleClaim(event.currentTarget)
        }
        customClassName={`${isClaimable ? 'claimable' : ''} 
        ${swiping ? 'alertSwipe' : ''} 
        ${hidden ? 'hidden' : ''}`}
        buttonText={buttonText}
        coins={quest.coinsReward}
        piggies={quest.piggyReward}
        completed={isClaimable}
      />
    </>
  )
}

export default QuestAlert
