import React, { useContext, useEffect } from 'react'
import { Alert, Box, Container, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import AuthContext from '../../context/AuthContext'
import LoaderContext from '../../context/LoaderContext'
import { ConnectGmailHowItWorks } from '../../utils/icons'
import Layout from '../../components/layout/Details'
import { formatNumber, handleError } from '../../utils/utils'
import { getAllUserOrders } from '../../services/ordersService'
import PageInner from '../../components/layout/PageInner'
import EarnGiftCards from '../../components/EarnGiftCards'
import RedemptionHistory from './components/RedemptionHistory'

import './index.css'

const AccountBalance = () => {
  const { user } = useContext(AuthContext)

  const { data: redemptions, isLoading: isFetching } = useQuery(
    ['redemptions', user.id],
    () => getAllUserOrders(user.id),
    {
      onError: handleError,
    }
  )

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  useEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  const navigate = useNavigate()

  const goHowItWorks = () => navigate('/how-it-works')

  const formattedPoints = formatNumber(user.points)
  const formattedPiggyBanks = formatNumber(user.piggyBanks)

  const BALANCE_COINS = [
    {
      name: 'Piggy Banks',
      value: formattedPiggyBanks,
      className: 'priceText pig',
      imgSrc: '/images/pig.png',
    },
    {
      name: 'Coins',
      value: formattedPoints,
      className: 'priceText coin',
      imgSrc: '/images/coin.png',
    },
  ]

  return (
    <>
      <PageInner customClassName={isLoading ? 'blured' : ''}>
        <Layout
          title="Account balance"
          headContent={
            <div className="accountBalanceHowItWorks" onClick={goHowItWorks}>
              <ConnectGmailHowItWorks />
            </div>
          }
        >
          <Container maxWidth="sm">
            <Box sx={styles.coins}>
              {BALANCE_COINS.map(({ name, value, className, imgSrc }) => (
                <Alert key={name} sx={styles.coinAlert} severity="info" icon={false}>
                  <Typography variant="h4">{name}</Typography>
                  <Typography
                    variant="inherit"
                    sx={styles.coinAlertValue}
                    className={`accountBalanceItem ${className}`}
                  >
                    <img src={imgSrc} /> {value}
                  </Typography>
                </Alert>
              ))}
            </Box>
            <div className="accountBalanceExplain">
              <div className="accountBalanceExplainText">
                <Typography variant="h1">How to earn rewards</Typography>
                <EarnGiftCards isBalance={true} />
              </div>
              <div className="accountBalanceExplainText">
                <Typography variant="h1">How to get gift cards</Typography>
                <Typography variant="h4" sx={styles.text}>
                  <br />
                  Combine <img src="/images/pig.png" /> & <img src="/images/coin.png" /> to redeem
                  gift cards. <br />
                  <br />
                </Typography>
                <Typography
                  variant="h4"
                  className="accountBalanceGetResultText"
                  sx={styles.getResultText}
                >
                  <div className="valueGradientWrap accountBalanceValueWrap">
                    <Typography variant="body2" className="priceText pig">
                      100 <img src={`/images/pig.png`} />
                    </Typography>
                  </div>
                  <span className="accountBalanceGetResultTextPlus">{' + '}</span>
                  <div className="valueGradientWrap accountBalanceValueWrap">
                    <Typography variant="body2" className="priceText coin">
                      1,000 <img src={`/images/coin.png`} />
                    </Typography>
                  </div>
                  = <strong>$1</strong> in gift cards
                </Typography>
              </div>
            </div>
          </Container>

          <RedemptionHistory redemptions={redemptions} />
        </Layout>
      </PageInner>
    </>
  )
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  headerText: {
    color: '#27272A',
  },
  coins: {
    display: 'flex',
    gap: '12px',
  },
  coinAlert: {
    borderRadius: '12px',
    background: '#FFFFFF',
    width: '100%',
    '& .MuiAlert-message': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  coinAlertValue: {
    marginTop: '12px',
    textAlign: 'left',
  },
  getResultText: {
    fontFamily: 'var(--poppins-font)',
    fontWeight: 400,
    color: '#000000',
    strong: {
      fontWeight: 700,
    },
  },
  text: {
    color: '#000000',
    fontWeight: 400,
  },
}

export default AccountBalance
