import { Typography } from '@mui/material'

import BottomButton from '../common/BottomButton'
import { GAME_CARD_OFFER_TYPE, OFFER_QUALITY } from '../../constants'
import OfferTask from '../offerTask'
import QualityTag from './QualityTag'
import AnimatedPiggy from './AnimatedPiggy'

const OfferCardFooter = ({
  type,
  isValidating,
  activeTask,
  quality,
  game,
  handleTryGame,
  activatedOffer,
}) => {
  if (type === GAME_CARD_OFFER_TYPE.ACTIVATED) {
    return <ActivatedOffer isValidating={isValidating} activeTask={activeTask} />
  }

  return (
    <>
      <QualityTag qualityName={quality} />
      {game.isReferral ? (
        <Typography variant="body2" sx={styles.text}>
          {game.valueStatement}
        </Typography>
      ) : (
        type === GAME_CARD_OFFER_TYPE.FEATURED && (
          <FeaturedOffer handleTryGame={handleTryGame} activatedOffer={activatedOffer} />
        )
      )}
    </>
  )
}

const ActivatedOffer = ({ isValidating, activeTask }) => {
  if (isValidating) {
    return <QualityTag qualityName={OFFER_QUALITY.FEATURED} text="Validating install" />
  }

  if (activeTask) {
    return <OfferTask {...activeTask} customClassName="activatedOfferCardTask" />
  }

  return null
}

const FeaturedOffer = ({ handleTryGame, activatedOffer }) => (
  <BottomButton style={styles.featuredButton} handleClick={handleTryGame}>
    <Typography variant="body2" sx={styles.featuredButtonText}>
      {activatedOffer ? 'Play! ' : 'Try Game! '}
    </Typography>
    <AnimatedPiggy />
  </BottomButton>
)

const styles = {
  featuredButton: {
    width: 'max-content',
    padding: '4px 7px',
    background: '#FF6492',
    display: 'flex',
    gap: '4px',
    '&:hover': {
      background: '#db517a',
    },
  },
  featuredButtonText: {
    color: '#ffffff',
    fontWeight: 600,
  },
  text: {
    color: '#ffffff',
    margin: 'auto 0',
  },
}

export default OfferCardFooter
