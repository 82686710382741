import React from 'react'

import './index.css'
import { Typography } from '@mui/material'
import UnlockingRewardsModalContent from '../ModalContent/UnlockingRewards'

/**
 * A component for displaying an information block for the user about activation of the offer
 * It is displayed for the user until the offer is activated
 *
 * @component
 * @param {function} showModal - function for showing the modal
 * @returns {ReactNode} A React element that renders the information about and of blocking
 */
const InstallToUnlock = ({ showModal }) => {
  /**
   * A function for showing the 'Unlocking Rewards' modal
   *
   * @function
   * @returns {void}
   */
  const handleShowUnlockingRewardsModal = () =>
    showModal({
      title: 'Unlocking Rewards',
      text: <UnlockingRewardsModalContent />,
      titleVariant: 'title',
      isCustomText: true,
      actionButtons: <></>,
      closeButton: true,
    })

  return (
    <div className="gameDetailsSpecialOfferInstallToUnlock">
      <img src="/images/lock.png" />
      <Typography variant="title" sx={styles.title}>
        Install & open game to unlock rewards
      </Typography>
      <div className="gameDetailsSpecialOfferInstallToUnlockContent">
        <Typography variant="h4" sx={styles.text}>
          Installs need to be verified through our game partners before you can earn rewards.
        </Typography>
        <Typography variant="h4" sx={styles.learnMore} onClick={handleShowUnlockingRewardsModal}>
          Learn more...
        </Typography>
      </div>
    </div>
  )
}

const styles = {
  title: {
    fontFamily: 'var(--poppins-font)',
    padding: '0 20px',
  },
  text: {
    fontWeight: 400,
  },
  learnMore: {
    fontWeight: 700,
    textDecoration: 'underline',
  },
}

export default InstallToUnlock
