import { useEffect } from 'react'
import { ThemeProvider } from '@mui/system'
import * as Sentry from '@sentry/react'
import { decodeToken } from 'react-jwt'

import {
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'

import posthog from 'posthog-js'
import { ref, onChildChanged } from 'firebase/database'
import { db } from './config/firebaseConfig'
import AuthProvider from './providers/AuthProvider'
import { ADMIN_ROUTES, NOT_AUTHORIZED_ROUTES, PROTECTED_ROUTES, PUBLIC_ROUTES } from './routes'
import { MAIN_THEME } from './utils/muiThemes'
import HistoryProvider from './providers/HistoryProvider'
import { NotFound } from './pages'
import LoaderProvider from './providers/LoaderProvider'
import Toaster from './components/CustomToaster'
import { EnvAdapter } from './utils/envAdapter'
import useLocalStorage from './hooks/useLocalStorage'
import { ERRORS, LOCAL_STORAGE } from './constants'
import { initializeTagManager } from './utils/utils'
import GlobalError from './pages/GlobalError'

import './App.css'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment:
    (window.ReactNativeWebView ? 'webview-' : '') + process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: EnvAdapter.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  profilesSampleRate: EnvAdapter.REACT_APP_SENTRY_PROFILES_SAMPLE_RATE,
  enabled: process.env.REACT_APP_NODE_ENV !== 'development',
  replaysOnErrorSampleRate: EnvAdapter.REACT_APP_SENTRY_REPLAYS_SAMPLE_RATE,
})

const App = () => {
  const location = useLocation()

  const { getItem } = useLocalStorage()
  useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  useEffect(() => {
    initializeTagManager()
    // clear cache after new deploy process
    const clearCacheRef = ref(db, 'clear_cache_action')
    const versionRef = ref(db, 'version')

    onChildChanged(clearCacheRef, () => {
      window.location.href = window.location.href.replace(/#.*$/, '')
    })

    onChildChanged(versionRef, (snapshot) => {
      const version = snapshot.val()
      let userId = null
      const token = getItem(LOCAL_STORAGE.JWT_TOKEN)
      if (token) {
        userId = decodeToken(token)?.id
      }
      if (version !== process.env.REACT_APP_VERSION) {
        Sentry.captureEvent({
          message: ERRORS.CACHE,
          level: 'error',
          extra: { userId },
        })
      }
    })
  }, [])

  // Check activity each time the user returns to the app
  // reload the page if the last activity was more than 30 minutes ago.
  document.addEventListener('visibilitychange', () => {
    const date = new Date(getItem(LOCAL_STORAGE.LAST_ACTIVITY))
    const currentDate = new Date()
    if (
      window.location.pathname !== '/admin' &&
      currentDate.getTime() - date.getTime() > 1000 * 60 * 30
    ) {
      window.location.href = window.location.href.replace(/#.*$/, '')
    }
  })
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <LoaderProvider>
      <ThemeProvider theme={MAIN_THEME}>
        <HistoryProvider>
          <AuthProvider>
            <SentryRoutes>
              {[
                ...PUBLIC_ROUTES,
                ...PROTECTED_ROUTES,
                ...NOT_AUTHORIZED_ROUTES,
                ...ADMIN_ROUTES,
              ].map(({ path, Component }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Sentry.ErrorBoundary
                      fallback={<GlobalError />}
                      key={path + '-boundary'}
                      showDialog
                    >
                      <Component />
                    </Sentry.ErrorBoundary>
                  }
                  errorElement={<GlobalError />}
                />
              ))}
              <Route path="*" element={<NotFound />} />
            </SentryRoutes>
            <Toaster />
          </AuthProvider>
        </HistoryProvider>
      </ThemeProvider>
    </LoaderProvider>
  )
}

export default App
