import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ref, onChildChanged, onValue } from 'firebase/database'
import toast from 'react-hot-toast'

import { db } from '../config/firebaseConfig'
import AuthContext from '../context/AuthContext'
import MessageLayout from '../components/layout/Message'
import { createOrder } from '../services/ordersService'
import { handleError, isUserOutOfAttempts } from '../utils/utils'
import {
  ERROR_TEXT,
  IDENTITY_VERIFICATION_STATUS,
  LOCAL_STORAGE,
  MAIN_PAGE,
  STRIPE_VERIFICATION_CONTACT_PARAGRAPH,
  SUPPORT_MAIL,
  VERIFICATION_STATUS,
} from '../constants'
import { getUserById } from '../services/userService'
import LoaderContext from '../context/LoaderContext'
import FullPageLoader from '../components/pageLoader'
import useLocalStorage from '../hooks/useLocalStorage'

/**
 * A page to display the user's verification status
 * Status may be verified, unverified, not finished or rejected for various reasons
 *
 * @component
 * @returns {ReactNode} A React element that renders user verification result page
 */
const VerificationResult = () => {
  const { user, updateUserData } = useContext(AuthContext)
  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  const [verificationResult, setVerificationResult] = useState()
  const [outOfAttempts, setOutOfAttempts] = useState(false)

  const { getItem, removeItem } = useLocalStorage()

  const navigate = useNavigate()

  useEffect(() => {
    const notificationRef = ref(db, `identity_verification/${user.id}`)

    const notificationHandler = async (snapshot) => {
      setVerificationResult(snapshot.val().state)

      showLoader()

      if (snapshot.val().state === IDENTITY_VERIFICATION_STATUS.FINISHED) {
        await getUserById(user.id)
          .then((data) => {
            updateUserData(data)
            setOutOfAttempts(isUserOutOfAttempts(data))
          })
          .catch(handleError)
      }
      hideLoader()
    }

    onValue(notificationRef, notificationHandler)
    onChildChanged(notificationRef, notificationHandler)
  }, [])

  /**
   * Universal function for a button on a page that changes the page or performs the purchase of a gift card depending on the verification result
   *
   * @function
   * @returns {void}
   */
  const handleClick = async () => {
    showLoader()
    const verificationStatus = user?.identityVerification?.status

    const isVerified = verificationStatus === VERIFICATION_STATUS.VERIFIED
    const isStripeRejected =
      verificationStatus === VERIFICATION_STATUS.UNVERIFIED ||
      verificationStatus === VERIFICATION_STATUS.STRIPE_REJECTED
    const isUsedDuplicateId = verificationStatus === VERIFICATION_STATUS.PLAYBACK_DUPLICATE_REJECTED
    const isCountryIssue =
      verificationStatus === VERIFICATION_STATUS.COUNTRY_MISMATCH ||
      verificationStatus === VERIFICATION_STATUS.COUNTRY_INELIGIBLE

    if (isVerified) {
      const giftCardId = getItem(LOCAL_STORAGE.GIFT_CARD_ID)
      const denomination = getItem(LOCAL_STORAGE.GIFT_CARD_DENOMINATION)

      if (giftCardId && denomination) {
        return createOrder({ giftCardId, denomination })
          .then((result) => {
            updateUserData(result.updatedUser)
            removeItem(LOCAL_STORAGE.GIFT_CARD_ID)
            removeItem(LOCAL_STORAGE.GIFT_CARD_DENOMINATION)

            navigate(`/order/${result.orderId}/success`)
          })
          .catch((err) => {
            handleError(err, ERROR_TEXT.choose_gift_card)
            navigate('/rewards')
          })
          .finally(hideLoader)
      }

      toast.error(ERROR_TEXT.choose_gift_card)

      navigate('/rewards')
    }

    if ((isStripeRejected && outOfAttempts) || isUsedDuplicateId) {
      navigate(MAIN_PAGE)
      return
    }

    hideLoader()
    if (isStripeRejected || isCountryIssue) {
      navigate('/verification')
    }
  }

  const handleBackArrowClick = () => {
    navigate('/rewards')
  }

  const verificationStatusContent = {
    [VERIFICATION_STATUS.VERIFIED]: {
      title: 'Congrats! Your identify has been verified!',
      buttonText: 'Finish Gift Card Redemption',
      text: (
        <>
          <p>
            Thank you for completing our identity verification process. Your account has now been
            fully verified and you can now use all the features of our platform without any
            restrictions.
          </p>
          <p>
            If you have any questions or concerns, please do not hesitate to contact our support
            team. We are always here to help you. Thank you again for your cooperation and for being
            a valued member of our platform.
          </p>
        </>
      ),
      showPlaybackLogo: true,
    },
    [VERIFICATION_STATUS.STRIPE_REJECTED]: {
      title: 'We were unable to verify your identity using the information provided',
      buttonText: outOfAttempts ? 'Done' : 'Retry',
      text: outOfAttempts ? (
        <>
          <p>Unfortunately we were unable to verify your identity.</p>
          <p>
            Please contact <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a> for further
            assistance.
          </p>
        </>
      ) : (
        <>
          <p>Verification failure reason: {user?.identityVerification?.failureReason}</p>
          <p>
            Please double-check the information you provided and try again. If you continue to
            experience issues, please contact our support team for assistance.
          </p>
        </>
      ),
      showPlaybackLogo: true,
    },
    [VERIFICATION_STATUS.PLAYBACK_DUPLICATE_REJECTED]: {
      title: 'This ID is already connected to another Playback account.',
      buttonText: 'Done',
      text: (
        <>
          <p>
            Unfortunately, it appears that another account has used this same ID previously.
            Playback only allows one account per person. To continue using Playback please return to
            using the original account connected to this ID.
          </p>
          {STRIPE_VERIFICATION_CONTACT_PARAGRAPH}
        </>
      ),
      showPlaybackLogo: false,
    },
    [VERIFICATION_STATUS.COUNTRY_MISMATCH]: {
      title: 'The country of the ID used does not match the country of your Playback account.',
      buttonText: 'Done',
      text: (
        <>
          <p>
            It was found that your ID verification was in violations of our Terms of Service. The
            country associated with your account did not match the country of the ID that you used
            to verify your identity. As a result, we are unable to verify your account.
          </p>
          {STRIPE_VERIFICATION_CONTACT_PARAGRAPH}
        </>
      ),
      showPlaybackLogo: false,
    },
    [VERIFICATION_STATUS.COUNTRY_INELIGIBLE]: {
      title: 'The country of the ID used is not a supported country for Playback.',
      buttonText: 'Done',
      text: (
        <>
          <p>
            It was found that your ID is from a country that Playback does not support so we will be
            unable to verify your account.
          </p>
          {STRIPE_VERIFICATION_CONTACT_PARAGRAPH}
        </>
      ),
      showPlaybackLogo: false,
    },
    [VERIFICATION_STATUS.UNVERIFIED]: {
      title: 'We were unable to verify your identity using the information provided',
      buttonText: outOfAttempts ? 'Done' : 'Retry',
      text: outOfAttempts ? (
        <>
          <p>Unfortunately we were unable to verify your identity.</p>
          <p>
            Please contact <a href={`mailto:${SUPPORT_MAIL}`}>{SUPPORT_MAIL}</a> for further
            assistance.
          </p>
        </>
      ) : (
        <>
          <p>Verification failure reason: {user?.identityVerification?.failureReason}</p>
          <p>
            Please double-check the information you provided and try again. If you continue to
            experience issues, please contact our support team for assistance.
          </p>
        </>
      ),
      showPlaybackLogo: true,
    },
  }

  const userVerificationStatusContent = verificationStatusContent[user.identityVerification.status]

  if (isLoading) {
    return <FullPageLoader />
  }

  if (verificationResult !== IDENTITY_VERIFICATION_STATUS.FINISHED) {
    const title = 'Currently stripe is processing your identity data'
    return (
      <MessageLayout
        isLoading={isLoading}
        title={title}
        handleBackArrowClick={handleBackArrowClick}
      >
        <p>When Playback received your identity verification results we will update this page</p>
      </MessageLayout>
    )
  }

  return (
    <MessageLayout
      title={userVerificationStatusContent.title}
      button={{
        text: userVerificationStatusContent.buttonText,
        handleClick,
      }}
      handleBackArrowClick={handleBackArrowClick}
      showPlaybackLogo={userVerificationStatusContent.showPlaybackLogo}
    >
      {userVerificationStatusContent.text}
    </MessageLayout>
  )
}

export default VerificationResult
