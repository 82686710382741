import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'

import AuthContext from '../../../../context/AuthContext'
import OfferAlert from '../../../../components/offerAlert'
import { claimPurchaseReward } from '../../../../services/userRewardsService'
import { createVirtualCurrencyImagesArrays } from '../../../../utils/utils'
import { handleHeaderCounter } from '../../../../utils/animations'

import './index.css'

const ClaimRewardCard = ({ reward }) => {
  const { user, updateUserData } = useContext(AuthContext)

  const [coinsArray, setCoinsArray] = useState([])
  const [pigsArray, setPigsArray] = useState([])
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const handleClaim = (sourceDiv) => {
    createVirtualCurrencyImagesArrays(
      sourceDiv,
      reward.earnedCoins,
      reward.earnedPiggy,
      setCoinsArray,
      setPigsArray,
      0.5
    )

    setSwiping(true)
    setTimeout(() => {
      setHidden(true)
      handleHeaderCounter(false, reward.earnedCoins)
      handleHeaderCounter(true, reward.earnedPiggy)

      claimPurchaseReward(reward.id).then(({ updatedUser }) => updateUserData(updatedUser))
    }, 1000)
  }

  return (
    <>
      <Typography variant="body2">{coinsArray}</Typography>
      <Typography variant="body2">{pigsArray}</Typography>
      <OfferAlert
        icon={
          <img
            src={
              reward?.platformsIconUrl?.find(
                (platform) => platform.os === user.platform.toLowerCase()
              )?.url || reward?.platformsIconUrl?.[0]?.url
            }
            className="gamesListClaimRewardsImage"
          />
        }
        title={`${reward.gameName} purchase`}
        handleClick={(event) => handleClaim(event.currentTarget)}
        customClassName={`${swiping ? 'alertSwipe' : ''} ${hidden ? 'hidden' : ''}`}
        coins={reward.earnedCoins}
        piggies={reward.earnedPiggy}
        buttonText="Claim!"
        completed={false}
      >
        You earned rewards for your purchase!
      </OfferAlert>
    </>
  )
}

export default ClaimRewardCard
