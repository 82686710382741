import React, { useState } from 'react'

import DetailsHeader from './Header'

import './index.css'

const Layout = ({
  title,
  headContent,
  customClassName = '',
  headContainerStyles = {},
  handleGoBack = null,
  children,
}) => {
  const [visible, setVisible] = useState(true)

  const handleHide = () => setVisible(false)
  return (
    <div className={`detailsLayout ${customClassName} ${!visible ? 'hide' : ''}`}>
      <DetailsHeader
        handleGoBack={handleGoBack}
        title={title}
        handleHide={handleHide}
        containerStyles={headContainerStyles}
      >
        {headContent}
      </DetailsHeader>
      <div className="detailsLayoutContent">{children}</div>
    </div>
  )
}

export default Layout
