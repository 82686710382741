import { Container, Typography } from '@mui/material'

import { CURRENCY_SYMBOL, PIGGY_BANKS_MULTIPLIER, POINTS_MULTIPLIER } from '../../../../constants'
import RedemptionHistoryItem from './Item'

import './index.css'

const RedemptionHistory = ({ redemptions }) => {
  return (
    <>
      <Container maxWidth="sm" sx={styles.container}>
        <div className="accountBalanceRedemptionInner">
          <Typography variant="h3" sx={styles.text}>
            Redemption History
          </Typography>
          <div className="accountBalanceRedemptionContent">
            {!redemptions?.length > 0 && (
              <Typography variant="body2" sx={styles.text}>
                All of your historical gift card redemptions show up here. You don’t have any yet,
                so get out there and start earning some rewards!
              </Typography>
            )}
            {redemptions?.map(({ id, giftCard, claimedAt, currency, totalPrice }) => (
              <RedemptionHistoryItem
                key={id}
                title={`${giftCard.name} Gift Card`}
                redeem={claimedAt}
                value={`${CURRENCY_SYMBOL[currency]}${totalPrice}`}
                coins={POINTS_MULTIPLIER(totalPrice)}
                pigs={PIGGY_BANKS_MULTIPLIER(totalPrice)}
                imageUrl={giftCard.image}
              />
            ))}
          </div>
        </div>
      </Container>
    </>
  )
}

const styles = {
  container: {
    padding: 0,
  },
  text: {
    padding: '0 16px',
  },
}

export default RedemptionHistory
