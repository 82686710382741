import { Alert, AlertTitle, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import useTimer from '../../hooks/useTimer'
import { CheckMark, Clock } from '../../utils/icons'
import { TIMER_EXPIRED } from '../../constants'
import OfferTaskValue from './Value'

import './index.css'

const OfferTask = ({
  title,
  icon = false,
  coins,
  piggies,
  expirationTimestamp,
  validationTimestamp,
  first,
  disabled,
  active,
  invalidateQueryKeys,
  children,
  text = '',
  completed = false,
  customClassName = '',
  earn = false,
}) => {
  const timer = useTimer(expirationTimestamp, invalidateQueryKeys, true)

  const queryClient = useQueryClient()

  const handleValidating = (validatingTimestamp) => {
    if (
      (new Date().getTime() - process.env.REACT_APP_OFFER_PLAY_DAY_VALIDATING_TIME) / 1000 >=
      validatingTimestamp
    ) {
      queryClient.invalidateQueries({
        queryKey: invalidateQueryKeys,
      })
    }
  }

  useEffect(() => {
    if (validationTimestamp) {
      handleValidating(validationTimestamp._seconds)
      const interval = setInterval(() => handleValidating(validationTimestamp._seconds), 1000)

      return () => clearInterval(interval)
    }
  }, [validationTimestamp])

  return (
    <Alert
      severity="info"
      sx={styles.alert}
      icon={
        disabled ? (
          <div className="alertIconWrap disabled">
            <img src="/images/lock.png" />
          </div>
        ) : (
          icon
        )
      }
      className={`gameDetailsOfferTask ${disabled ? 'disabled' : ''} ${
        first && !disabled ? 'first' : ''
      } ${active ? 'active' : ''} ${earn ? 'earn' : ''} ${customClassName}`}
      disabled={false}
    >
      <div className="gameDetailsOfferTaskContent">
        <AlertTitle>
          <Typography variant="h4" sx={styles.title}>
            {title}
          </Typography>
        </AlertTitle>
        {text && <Typography variant="body2">{text}</Typography>}
        {completed && (
          <Typography variant="body2" sx={styles.completed}>
            <CheckMark />
            Completed
          </Typography>
        )}
      </div>

      <div className="gameDetailsOfferTaskItems">
        {timer && timer !== TIMER_EXPIRED && (
          <div className="gameDetailsOfferTaskTimer">
            <Typography variant="body2">
              <Clock /> {timer}
            </Typography>
          </div>
        )}
        <div className="gameDetailsOfferTaskValues">
          {piggies > 0 && <OfferTaskValue value={piggies} isPig={true} />}
          {coins > 0 && <OfferTaskValue value={coins} isPig={false} />}
          {!disabled && children}
        </div>
      </div>
    </Alert>
  )
}

const styles = {
  alert: {
    padding: '12px',
    border: '0px 0px 1px 0px',
    borderRadius: '18px',
    '& .MuiAlert-icon svg': {
      width: 'unset',
      height: 'unset',
    },
    '& .MuiAlert-icon': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiAlert-message': {
      padding: 0,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      justifyContent: 'space-between',
      width: '100%',
    },
    '& .MuiAlertTitle-root': {
      margin: '0',
    },
    '& .MuiAlert-action': {
      marginRight: 0,
      padding: '0 0 0 8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
  },
  completed: {
    color: '#039F76',
  },
  title: {
    color: '#1F1F1F',
    fontWeight: 500,
    fontFamily: 'var(--poppins-font)',
  },
  button: {
    maxWidth: 'max-content',
    padding: '6px 16px',
  },
  text: {
    fontFamily: 'var(--poppins-font)',
    color: '#636874',
  },
}

export default OfferTask
