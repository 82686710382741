import React, { useEffect, useRef, useState } from 'react'
import { CardContent, Typography } from '@mui/material'

//assets
import GamePad from '../../assets/offers/gamepad.png'
import Cart from '../../assets/offers/cart.png'
//others
import useTimer from '../../hooks/useTimer'
import { LOCAL_STORAGE, OFFER_QUALITY, TIMER_EXPIRED, GAME_CARD_OFFER_TYPE } from '../../constants'
import Rating from '../rating'
import OfferCardTimer from '../timer'
import ImageCrop from '../ImageCrop'
import {
  checkDayVisible,
  checkOfferValidating,
  checkPlaytimeVisible,
  getDayExpirationTimestamp,
} from '../../utils/utils'
import useOfferInstallValidation from '../../hooks/useOfferInstallValidation'
import Image from './Image'
import Slider from './Slider'
import CardFooter from './CardFooter'

import './index.css'

//The width of the pictures depends on the type of offer card
const IMAGE_WIDTH = {
  [GAME_CARD_OFFER_TYPE.ACTIVATED]: 500,
  [GAME_CARD_OFFER_TYPE.FEATURED]: 400,
  [GAME_CARD_OFFER_TYPE.DEFAULT]: 300,
}

//The width of the card depends on the type of offer card
const CARD_HEIGHT = {
  [GAME_CARD_OFFER_TYPE.FEATURED]: '175px',
  [GAME_CARD_OFFER_TYPE.ACTIVATED]: '224px',
  [GAME_CARD_OFFER_TYPE.DEFAULT]: '144px',
}

const OfferCard = ({
  game,
  isLibrary = false,
  activatedOffer,
  specialOffer,
  type = GAME_CARD_OFFER_TYPE.DEFAULT,
  isAutoSwiping,
}) => {
  const [activeTask, setActiveTask] = useState(null)
  const [isValidating, setValidating] = useState(false)
  const [imageUrl, setImageUrl] = useState('')

  const expirationTimestamp =
    activatedOffer?.expirationTimestamp || specialOffer?.endTimestamp || game?.endTimestamp

  const quality =
    type === GAME_CARD_OFFER_TYPE.FEATURED
      ? OFFER_QUALITY.FEATURED.name
      : game?.offerQuality || specialOffer?.offerQuality || activatedOffer?.offerQuality

  const cardRef = useRef(null)

  const timer = useTimer(expirationTimestamp, ['featuredGameOffers'], true)

  useOfferInstallValidation(isValidating, activatedOffer, [], () => setValidating(false))

  useEffect(() => {
    if (!activatedOffer) {
      return
    }

    const isValidating = checkOfferValidating(activatedOffer, game)

    setValidating(isValidating)

    if (isValidating) {
      return
    }

    // TODO: Fix this implementation
    let activeTask

    //Don't set an active task if the offer has expired
    if (
      activatedOffer.expirationTimestamp &&
      activatedOffer.expirationTimestamp._seconds <= new Date().getTime() / 1000
    ) {
      return
    }

    activatedOffer?.dayXRewards?.some((day) => {
      if (checkDayVisible(day, timer) && !('claimed' in day)) {
        activeTask = {
          coins: day.rewards.coins,
          piggies: day.rewards.piggyBanks,
          title: 'Play on Day ' + day.day,
          icon: <img src={GamePad} />,
          expirationTimestamp: getDayExpirationTimestamp(day),
        }
        return true
      }

      return false
    })

    if (!activeTask) {
      activatedOffer?.playtimeRewards?.some((playtime, index) => {
        if (checkPlaytimeVisible(activatedOffer, playtime, index) && !playtime.completed) {
          activeTask = {
            coins: playtime.rewards.coins,
            piggies: playtime.rewards.piggyBanks,
            title:
              index === 0 && !activatedOffer.mmpAttributionRequiredForRewards
                ? `Download & Play ${playtime.minutes} min`
                : `Play ${playtime.minutes} min`,
            icon: <img src={GamePad} />,
          }
          return true
        }

        return false
      })
    }

    if (!activeTask && activatedOffer?.mmpEventTasks) {
      const currentMMPEventTask = activatedOffer.mmpEventTasks.find((task) => !task.completed)
      if (currentMMPEventTask) {
        activeTask = {
          coins: currentMMPEventTask.rewards.coins,
          piggies: currentMMPEventTask.rewards.piggyBanks,
          title: currentMMPEventTask.taskDescription,
          icon: <img src={GamePad} />,
        }
      }
    }

    if (!activeTask && activatedOffer.eligibleSpendAmount - activatedOffer.dollarsSpent > 0) {
      const eligibleSpendAmount = activatedOffer?.eligibleSpendAmount - activatedOffer?.dollarsSpent
      activeTask = {
        icon: <img src={Cart} />,
        coins: Math.floor(activatedOffer.coinsPerDollar * eligibleSpendAmount),
        piggies: Math.floor(activatedOffer.piggyPerDollar * eligibleSpendAmount),
        title: <>Spend up to ${eligibleSpendAmount.toFixed(2)}</>,
        earn: true,
      }
    }

    setActiveTask(activeTask)
  }, [activatedOffer, isValidating])

  const handleTryGame = (e) => {
    e.stopPropagation()

    sessionStorage.setItem(LOCAL_STORAGE.OPEN_MMP_LINK, true)

    cardRef.current.click()
  }

  return (
    <>
      <ImageCrop
        url={specialOffer?.featuredGraphic || game?.featuredGraphic}
        width={IMAGE_WIDTH[type]}
        setImageUrl={setImageUrl}
      />
      <CardContent
        sx={styles.content(isLibrary, type)}
        ref={cardRef}
        className={`offerCardInner ${type}`}
      >
        {!activatedOffer && 'isUAOffer' in game && !game.isUAOffer && (
          <div className="offerCardUAOfferTag">
            <Typography variant="body2" sx={styles.uaOfferText}>
              Existing user offer!
            </Typography>
          </div>
        )}
        {(expirationTimestamp || game.timer) && (
          <OfferCardTimer
            isActive={activatedOffer || game.activatedOffer}
            expired={timer === TIMER_EXPIRED}
            time={timer || game.timer}
            showExpiration={game?.showExpiration}
            customClassName="offerCardTimer myGamesOffer"
          />
        )}
        {type === GAME_CARD_OFFER_TYPE.FEATURED ? (
          <Slider game={game} isAutoSwiping={isAutoSwiping} storageKey={`video-${game.id}`} />
        ) : (
          <Image featuredGraphic={imageUrl} isSmall={type === GAME_CARD_OFFER_TYPE.DEFAULT} />
        )}
        <div className="offerCardContent">
          {isLibrary ? (
            <Typography variant="h2" sx={styles.text}>
              Special Offer
            </Typography>
          ) : (
            <div className="offerCardFeaturedName">
              <Typography variant="h2" sx={styles.text}>
                {game.gameName}
              </Typography>{' '}
              {type === GAME_CARD_OFFER_TYPE.FEATURED && <Rating offer={game} isSmall={true} />}
            </div>
          )}
          <div className="offerCardFooter">
            <CardFooter
              activeTask={activeTask}
              isValidating={isValidating}
              type={type}
              quality={quality}
              game={game}
              handleTryGame={handleTryGame}
              activatedOffer={activatedOffer}
            />
          </div>
        </div>
      </CardContent>
    </>
  )
}

const styles = {
  content: (isLibrary, type) => ({
    borderTop: isLibrary ? '1px solid #F59E0B' : 'none',
    height: CARD_HEIGHT[type],
    position: 'relative',
    boxShadow: '0px 4px 4px -4px #00000080 inset',
    padding: '0',
    '&:last-child': {
      paddingBottom: 0,
    },
    ...(type === GAME_CARD_OFFER_TYPE.DEFAULT && {
      '@media (min-width: 640px)': {
        height: '175px',
      },
    }),
  }),
  text: {
    color: '#ffffff',
    margin: 'auto 0',
  },
  uaOfferText: {
    color: '#ffffff',
    fontWeight: 600,
  },
}

export default OfferCard
