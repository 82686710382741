import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { ActionArrow, UpdateAction } from '../../../../utils/icons'

import './index.css'

const UserSettingsItems = ({ title, items }) => {
  return (
    <div className="userSettingsItem">
      <Typography variant="h2" sx={styles.general}>
        {title}
      </Typography>

      <Box sx={styles.generalItems}>
        {items.map(({ label, value, onClick, customSX, warning, onDelete }) => (
          <Box
            key={label || value}
            sx={customSX || styles.generalItem}
            className={!value && onClick ? 'userSettingsItemClickable' : ''}
            onClick={!value ? onClick : null}
          >
            <Typography variant="h4" sx={styles.generalItemTitle}>
              {label || value}
            </Typography>
            {value ? (
              <div className="userSettingsGeneralItemActionBox">
                <Typography variant="h4" sx={styles.generalItemValue(warning)}>
                  {!label ? warning : value}
                </Typography>
                {onClick && <UpdateAction onClick={onClick} />}
                {onDelete && <img src="/images/modalCloseButton.png" onClick={onDelete} />}
              </div>
            ) : (
              <ActionArrow />
            )}
          </Box>
        ))}
      </Box>
    </div>
  )
}
const styles = {
  general: {
    margin: '32px 0 20px',
  },
  generalItems: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  generalItem: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '12px',
    borderBottom: '1px solid #E4E4E7',
  },
  generalItemTitle: {
    fontWeight: 400,
  },
  generalItemValue: (warning) => ({
    fontWeight: 400,
    ...(warning && { color: '#EF4444' }),
  }),
}

export default UserSettingsItems
