import React from 'react'
import { Box, Typography } from '@mui/material'

import { formatNumber } from '../../../../utils/utils'

import './index.css'

const OfferContent = ({ title, text, gameName = '', coinsValue, children }) => {
  const formattedCoinsValue = formatNumber(coinsValue)

  return (
    <div className="gameDetailsOfferContainer">
      <div className="gameDetailsOfferInner">
        <div className="gameDetailsOffer">
          <Box sx={styles.head}>
            <Typography variant="h3">{title}</Typography>
          </Box>
          {text && <Typography variant="body2">{text}</Typography>}
          <div className="gameDetailsOfferContent">
            <div className="gameDetailsOfferText">
              <Typography variant="body2" sx={styles.content}>
                For every <strong>$1.00</strong> USD you spend in {gameName}, you’ll earn:
              </Typography>
              <Typography variant="h4" sx={styles.contentValue}>
                <div className="priceText coin">{formattedCoinsValue}</div>{' '}
                <img src="/images/coin.png" />{' '}
              </Typography>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

const styles = {
  content: {
    strong: {
      fontWeight: 600,
    },
  },
  contentValue: {
    fontWeight: 700,
    display: 'flex',
    gap: '4px',
    span: {
      fontWeight: 500,
    },
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  tags: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '-5px 0 14px',
  },
}

export default OfferContent
