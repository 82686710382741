import React from 'react'
import MessageLayout from '../components/layout/Message'

const CountryErrorPage = () => {
  const handleBackArrowClick = () => {
    window.location.href = `https://playbackrewards.com/`
  }

  return (
    <MessageLayout title="Country Not Supported" handleBackArrowClick={handleBackArrowClick}>
      Unfortunately, Playback Rewards is not available in your country at this time.
    </MessageLayout>
  )
}

export default CountryErrorPage
