import React, { useContext, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Typography } from '@mui/material'

import { useQuery } from 'react-query'
import LoaderContext from '../../context/LoaderContext'
import { getGiftCardById } from '../../services/giftCardsService'
import { CURRENCY_SYMBOL, PIGGY_BANKS_MULTIPLIER, POINTS_MULTIPLIER } from '../../constants'
import PriceText from '../../components/giftCards/PriceText'
import { formatNumber, handleError } from '../../utils/utils'
import BottomButton from '../../components/common/BottomButton'
import Layout from '../../components/layout/Details'
import AuthContext from '../../context/AuthContext'
import PageInner from '../../components/layout/PageInner'
import NotFound from '../NotFound'
import ConfirmOrderModal from './components/ConfirmOrderModal'

import './index.css'

/**
 * A page to display the Gift Card for user to purchase
 * The main parameters used to determine the value of a gift card are ID and denomination
 *
 * @component
 * @returns {ReactNode} A React element that renders gift card details page
 */
const GiftCardsDetails = () => {
  const { user } = useContext(AuthContext)
  const [modal, setModal] = useState(false)

  const { giftCardId, denomination } = useParams()

  const pointsValue = POINTS_MULTIPLIER(denomination)
  const piggyBanksValue = PIGGY_BANKS_MULTIPLIER(denomination)
  const formattedPointsValue = formatNumber(pointsValue)
  const formattedPiggiesValue = formatNumber(piggyBanksValue)

  const { data: giftCard, isFetching } = useQuery(
    ['giftCard', giftCardId],
    () => getGiftCardById(giftCardId),
    {
      onSuccess: () => hideLoader(),
      onError: (err) => {
        hideLoader()
        handleError(err)
      },
    }
  )

  const { isLoading, showLoader, hideLoader } = useContext(LoaderContext)

  useLayoutEffect(() => {
    isFetching ? showLoader() : hideLoader()
  }, [isFetching])

  const showConfirmOrderModal = () => setModal(true)
  const hideConfirmOrderModal = () => setModal(false)

  const SubmitButtonText = () => (
    <Typography variant="h4" className="giftCardDetailsSubmitButtonText">
      Buy:{' '}
      <PriceText
        userBalance={user?.piggyBanks}
        giftCardValue={piggyBanksValue}
        value={formattedPiggiesValue}
        isPig={true}
      />{' '}
      &{' '}
      <PriceText
        userBalance={user?.points}
        giftCardValue={pointsValue}
        value={formattedPointsValue}
        isPig={false}
      />
    </Typography>
  )

  if (!isLoading && !giftCard) {
    return <NotFound />
  }

  return (
    <PageInner>
      {giftCard && (
        <ConfirmOrderModal
          showModal={modal}
          hideModal={hideConfirmOrderModal}
          showLoader={showLoader}
          hideLoader={hideLoader}
          giftCard={giftCard}
          denomination={denomination}
          pointsValue={pointsValue}
          formattedPointsValue={formattedPointsValue}
          formattedPiggiesValue={formattedPiggiesValue}
          piggyBanksValue={piggyBanksValue}
        />
      )}
      <Layout title="Redeem Gift Card">
        <Container maxWidth="sm" sx={styles.container}>
          <div className={`giftCardDetailsInner ${isLoading ? 'blured' : ''}`}>
            <div className="giftCardDetailsContent">
              <img alt={giftCard?.name} src={giftCard?.image} className="giftCardDetailsImage" />
              <Typography variant="h2">{giftCard?.name} Gift Card</Typography>
              <Typography variant="h1">
                {CURRENCY_SYMBOL[giftCard?.currency]}
                {denomination} {giftCard?.currency}
              </Typography>
              <Typography
                variant="body2"
                className="giftCardDetailsDescription"
                dangerouslySetInnerHTML={{ __html: `<small>${giftCard?.terms}</small>` }}
              />
            </div>
            <BottomButton handleClick={showConfirmOrderModal} style={styles.closeButton}>
              <SubmitButtonText />
            </BottomButton>
          </div>
        </Container>
      </Layout>
    </PageInner>
  )
}

const styles = {
  container: {
    padding: 0,
  },
  closeButton: {
    border: '2px solid rgb(251, 146, 60)',
    backgroundColor: '#ffffff',
    color: '#000000',
    marginBottom: '20px',
  },
}

export default GiftCardsDetails
