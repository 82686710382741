import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'

//context
import AuthContext from '../../../../../context/AuthContext'
//components
import OfferAlert from '../../../../../components/offerAlert'
import MovingImage from '../../../../../components/movingImage'
// eslint-disable-next-line import/order
import Modal from './Modal'
//constants
import { FLY_COINS_MAX_COUNT, PIGS_DIVIDER } from '../../../../../constants'
//utils
import { handleHeaderCounter } from '../../../../../utils/animations'
import { getHeaderImageDiv } from '../../../../../utils/utils'

const PiggyBankChest = (config) => {
  const { user, updateUserData } = useContext(AuthContext)

  const [updatedUser, setUpdatedUser] = useState(user)

  const [pigsArray, setPigsArray] = useState([])
  const [modal, setModal] = useState(false)
  const [swiping, setSwiping] = useState(false)
  const [hidden, setHidden] = useState(false)

  const handleShowPiggyBankChestModal = () => setModal(true)

  const hideModal = () => setModal(false)

  const footerDiv = document.getElementsByClassName('gamesFooter')[0]

  const handleClaim = async (chestReward) => {
    const array = []

    for (let i = 0; i < Math.min(chestReward.piggyBanks / PIGS_DIVIDER, FLY_COINS_MAX_COUNT); i++) {
      array.push(
        <MovingImage
          key={'movingImagePiggyBankChest' + i}
          src="/images/pig.png"
          index={i}
          headerDiv={getHeaderImageDiv(true)}
          footerDiv={footerDiv}
          animationDuration={0.5}
        />
      )
    }

    setPigsArray(array)

    setSwiping(true)
    setTimeout(() => {
      setHidden(true)
      handleHeaderCounter(true, chestReward.piggyBanks)
      updateUserData(updatedUser)
    }, 1000)
  }

  return (
    <>
      <Modal
        showModal={modal}
        hideModal={hideModal}
        config={config}
        handleClaim={handleClaim}
        setUpdatedUser={setUpdatedUser}
      />
      <Typography variant="body2">{pigsArray}</Typography>
      <OfferAlert
        icon={<img src="/images/pigChestClosed.png" />}
        customClassName={`${swiping ? 'alertSwipe' : ''} ${hidden ? 'hidden' : ''}`}
        title={'Open a Piggy Bank Chest'}
        handleClick={handleShowPiggyBankChestModal}
        buttonText="Open"
      >
        Win up to 10,000 Piggy Banks!
      </OfferAlert>
    </>
  )
}

export default PiggyBankChest
