import React, { useCallback } from 'react'
import Slider from 'react-slick'
import { Box, Button, Typography } from '@mui/material'

//hooks
import useQueryGeoData from 'hooks/useQueryGeoData'
//others
import AuthLayout from '../../components/layout/Auth'
import {
  AUTHORIZATION_STATE,
  AVAILABLE_COUNTRIES_ABBRV,
  CREATE_ACCOUNT_SLIDER_ITEMS,
} from '../../constants'
import GoogleAuthButton from '../../components/auth/GoogleAuthButton'
import { generateGoogleOauth } from '../../services/authService'
import { handleError, handleRNLogin } from '../../utils/utils'
import AuthorizationTerms from '../../components/AuthorizationTerms'

const Content = ({ setState }) => {
  //Query hooks
  const { geoData } = useQueryGeoData()

  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: false,
    className: 'createAccountSlider',
    customPaging: () => <div className="createAccountSliderPage" />,
    appendDots: (dots) => (
      <div>
        <ul className="createAccountSliderDots">{dots}</ul>
      </div>
    ),
  }

  const handleClick = useCallback(async () => {
    const country = geoData?.country || ''

    if (country) {
      if (!AVAILABLE_COUNTRIES_ABBRV.includes(country)) {
        window.location.href = `/country-error?detected-country=${country}`
      }
    }

    if (window.ReactNativeWebView) {
      return handleRNLogin(geoData)
    }

    const authLink = await generateGoogleOauth('oauth', []).catch(handleError)
    window.location.href = authLink
  }, [geoData])

  return (
    <AuthLayout>
      <Slider {...sliderSettings}>
        {CREATE_ACCOUNT_SLIDER_ITEMS.map(({ image, text, title }) => (
          <div className="createAccountSliderItem" key={title}>
            <img src={image} />
            <Typography variant="h1" sx={styles.sliderTitle}>
              {title}
            </Typography>
            <Typography variant="body1" sx={styles.sliderText}>
              {text}
            </Typography>
          </div>
        ))}
      </Slider>
      <div>
        <div className="createAccountContentActionsButtons">
          <GoogleAuthButton handleClick={handleClick} />
          <Button
            sx={{ ...styles.button, ...styles.createAccountButton }}
            onClick={() => setState(AUTHORIZATION_STATE.DONT_HAVE)}
          >
            <Typography variant="h4" sx={styles.createAccountButtonText}>
              Don't have a gmail account?
            </Typography>
          </Button>
        </div>
        <Box sx={styles.footer}>
          <Button sx={styles.button} onClick={handleClick}>
            <Typography variant="h4" sx={styles.createAccountButtonText}>
              Already have an account? <strong>Log in</strong>
            </Typography>
          </Button>
          <AuthorizationTerms />
        </Box>
      </div>
    </AuthLayout>
  )
}

const styles = {
  sliderTitle: {
    marginTop: '32px',
  },
  sliderText: {
    marginTop: '12px',
  },
  button: {
    padding: '7px 24px',
    width: '100%',
    margin: '0 auto',
    background: 'none',
    textAlign: 'center',
    color: '#71717a',
    textTransform: 'none',
    borderRadius: '100px',
    fontWeight: 500,
  },
  createAccountButton: {
    padding: '12px 24px',
    textDecoration: 'underline',
    textUnderlinePosition: 'under',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  createAccountButtonText: {
    fontWeight: 400,
    fontFamily: 'var(--inter-font)',
    color: '#71717A',
  },
  footer: {
    margin: '36px 0 17px',
  },
}

export default Content
