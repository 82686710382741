import { useQuery } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import { captureEvent } from '@sentry/react'

//constants
import { ERRORS, IP_COUNTRY_DETECTION_API } from 'constants/index'
import QueryKeys from 'constants/queryKeys'
//types
import { GeoData } from 'types'

const useQueryGeoData = () => {
  const { data, isLoading, isFetching } = useQuery<AxiosResponse<GeoData, Error>>(
    QueryKeys.GET_GEO_DATA,
    () => axios.get(IP_COUNTRY_DETECTION_API),
    {
      onError: (error) => {
        captureEvent({
          message: ERRORS.IP_COUNTRY_DETECTION_RQ,
          level: 'error',
          extra: { err: error },
        })
      },
    }
  )
  return { geoData: data?.data, isLoading: isLoading || isFetching }
}

export default useQueryGeoData
