import { useContext } from 'react'
import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { captureEvent } from '@sentry/react'

//constants
import { ERRORS } from 'constants/index'
//contexts
import AuthContext from 'context/AuthContext'
//hooks
import useQueryDeviceInfo from 'hooks/useQueryDeviceInfo'
//services
import { twentyFourMetricsClick, twentyFourMetricsImpression } from 'services/24Metrics'
//types
import { TwentyFourMetricsRequest, TwentyFourMetricsAPIRequest } from 'types'

const use24Metrics = () => {
  const { user } = useContext(AuthContext)

  //Query hooks
  const { deviceInfo } = useQueryDeviceInfo()
  //Mutation hooks
  const { mutate: trackImpressionMutate } = useMutation<
    AxiosResponse,
    AxiosError,
    TwentyFourMetricsAPIRequest
  >((data) => twentyFourMetricsImpression(data), {
    onError: (error) => handleError(ERRORS.TWENTYFOUR_METRICS_TRACK_IMPRESSION_API_CALL, error),
  })
  const { mutate: trackClickMutate } = useMutation<
    AxiosResponse,
    AxiosError,
    TwentyFourMetricsAPIRequest
  >((data) => twentyFourMetricsClick(data), {
    onError: (error) => handleError(ERRORS.TWENTYFOUR_METRICS_TRACK_CLICK_API_CALL, error),
  })

  const handleError = (message: string, error: AxiosError | Error | unknown) => {
    captureEvent({
      message,
      level: 'error',
      extra: { err: error },
    })
  }

  const createRequest = (
    unifiedAppId: string,
    advertiser: string,
    campaignId: string,
    clickId?: string
  ) => {
    const request: TwentyFourMetricsRequest = {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      userId: (user as any)?.id || '',
      integrationID: process.env.REACT_APP_24_METRICS_INTEGRATION_ID || '',
      advertiser,
      offer: unifiedAppId,
      publisher: 'pbr',
      sub_id: unifiedAppId,
      sub_id2: campaignId,
      device_id: deviceInfo?.aaid || 'null',
      referer: 'pbrapp',
      fields:
        'redirect_url,country,city,isp,asn,external_transaction_id,rejection_reason,all_rejection_reasons',
    }
    if (clickId) {
      request.external_transaction_id = clickId
    }
    return request
  }

  const trackImpression24Metrics = async (
    unifiedAppId: string,
    advertiser: string,
    campaignId: string
  ) => {
    try {
      const request = createRequest(unifiedAppId, advertiser, campaignId)
      const response = await window.ASP.trackImpression(request)
      trackImpressionMutate({ ...request, ...response.data })
    } catch (error) {
      handleError(ERRORS.TWENTYFOUR_METRICS_TRACK_IMPRESSION, error)
    }
  }

  const trackClick24Metrics = async (
    unifiedAppId: string,
    advertiser: string,
    campaignId: string,
    clickId: string
  ) => {
    try {
      const request = createRequest(unifiedAppId, advertiser, campaignId, clickId)
      const response = await window.ASP.trackClick(request)
      trackClickMutate({ ...request, ...response.data })
    } catch (error) {
      handleError(ERRORS.TWENTYFOUR_METRICS_TRACK_CLICK, error)
    }
  }

  return { trackImpression24Metrics, trackClick24Metrics }
}

export default use24Metrics
