import { axiosBackendInstance } from '../axios'
import authAxios from '../authAxios'

export const getUserUnclaimedRewards = (userId) => {
  return authAxios.get(`/api/v1/rewards/unclaimed/${userId}`)
}

export const getFeaturedGameOffers = (userId) => {
  return authAxios.get(`/api/v1/users/${userId}/featuredGames`)
}

export const claimAggregatedReward = async (data) => {
  return axiosBackendInstance.put('/api/v1/rewards/claim/aggregated', data)
}

export const createSpecialReward = async (offerId, data) => {
  return axiosBackendInstance.post(`/api/v1/rewards/special-rewards/${offerId}`, data)
}

export const claimSpecialReward = async (offerId, data) => {
  return axiosBackendInstance.patch(`/api/v1/rewards/claim/special-rewards/${offerId}`, data)
}

export const getSpecialRewards = async (claimedDate = null, reasons = []) => {
  let reasonParams = ''
  reasons.map((reason) => (reasonParams += `&reason=${reason}`))

  return authAxios.get(`/api/v1/rewards?claimedDate=${claimedDate}${reasonParams}`)
}

export const claimPurchaseReward = async (rewardId) => {
  return axiosBackendInstance.put(`/api/v1/rewards/claim/purchase/${rewardId}`)
}
