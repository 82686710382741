import { DialogContent, Typography } from '@mui/material'

const SpendingModalContent = ({ coins, piggies, finalCoins, finalPiggies }) => {
  return (
    <DialogContent sx={styles.inner} className="gameDetailsSpecialOfferModal">
      <Typography variant="h4" sx={styles.text}>
        After offer activation, every new purchase receipt detected in your email account for this
        game will automatically earn rewards at the special rate of:
      </Typography>
      <Typography variant="h4" sx={styles.text}>
        Earn{' '}
        <div className="gameDetailsSpecialOfferModalValue">
          {piggies} <img src="/images/pig.png" />
        </div>{' '}
        &{' '}
        <div className="gameDetailsSpecialOfferModalValue">
          {coins} <img src="/images/coin.png" />
        </div>{' '}
        for every $1 spent
      </Typography>
      <Typography variant="h4" sx={styles.text}>
        Total spending rewards available in this offer:
        <br />
        <div className="gameDetailsSpecialOfferModalValue">
          {finalPiggies} <img src="/images/pig.png" />
        </div>{' '}
        &{' '}
        <div className="gameDetailsSpecialOfferModalValue">
          {finalCoins} <img src="/images/coin.png" />
        </div>
      </Typography>
    </DialogContent>
  )
}

const styles = {
  inner: {
    padding: '0px 20px',
  },
  text: {
    fontWeight: 500,
    fontFamily: 'var(--poppins-font)',
    color: '#0F172A',
    strong: {
      fontWeight: 700,
    },
  },
}

export default SpendingModalContent
