import React from 'react'

import './index.css'
import { Sparkle } from '../../../utils/icons'

const AnimatedPiggy = () => {
  return (
    <div className="offerCardAnimatedPiggy">
      <img src="/images/coinLoader.png" className="offerCardAnimatedPiggyCoinImage" />
      <img src="/images/pig.png" className="offerCardAnimatedPiggyPigImage" />
      <img src="/images/pig.png" className="offerCardAnimatedPiggyGrowingPigImage" />
      <div className="offerCardAnimatedPiggySparkles">
        <Sparkle />
        <Sparkle />
      </div>
    </div>
  )
}

export default AnimatedPiggy
