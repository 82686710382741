import { Typography } from '@mui/material'
import React from 'react'

import './PriceText.css'

const PriceText = ({ userBalance, giftCardValue, value, isPig }) => {
  const textGradient = isPig ? 'pig' : 'coin'
  const imgSrc = isPig ? '/images/pig.png' : '/images/coin.png'

  const getTextColor = () => {
    if (userBalance === undefined) {
      return null
    }
    if (userBalance < giftCardValue) {
      return 'red'
    }
    return textGradient
  }

  return (
    <Typography variant="h4" className={`priceText ${getTextColor()}`}>
      {value} {<img src={imgSrc} />}
    </Typography>
  )
}

export default PriceText
