import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

import AuthContext from '../../../../context/AuthContext'
import { createOrder } from '../../../../services/ordersService'
import { handleError, isUserOutOfAttempts, isUserVerified } from '../../../../utils/utils'
import Modal from '../../../../components/modal'
import { CURRENCY_SYMBOL, LOCAL_STORAGE } from '../../../../constants'
import BottomButton from '../../../../components/common/BottomButton'
import useLocalStorage from '../../../../hooks/useLocalStorage'

import './index.css'

/**
 * Dialog window for confirming the purchase of a gift card.
 *
 * @component
 * @param {boolean} showModal - Determines if a window is open
 * @param {function} hideModal - Close dialog window function
 * @param {function} showLoader - Show page loader function
 * @param {function} hideLoader - Hide page loader function
 * @param {object} giftCard - Gift Card object value with required id value
 * @param {string} denomination - Denomination of the gift card, by which its price is determined
 * @param {string} formattedPointsValue - Formatted to display coin value
 * @param {string} formattedPiggiesValue - Formatted to display piggy banks value
 * @param {number} piggyBanksValue - The price of piggy banks for this gift card
 * @returns {ReactNode} A React element that renders gift card purchase confirmation window.
 */
const ConfirmOrderModal = ({
  showModal,
  hideModal,
  showLoader,
  hideLoader,
  giftCard,
  denomination,
  pointsValue,
  formattedPointsValue,
  formattedPiggiesValue,
  piggyBanksValue,
}) => {
  const { user, updateUserData } = useContext(AuthContext)
  const navigate = useNavigate()
  const { setItem } = useLocalStorage()

  /**
   * Gift card order confirmation
   * Checks user verification status and sends the request to backend for gift card confirmation according to the results of which it changes the page
   *
   * @function
   * @returns {void}
   */
  const handleConfirm = async () => {
    const isVerified = isUserVerified(user)
    const outOfAttempts = isUserOutOfAttempts(user)

    if (isVerified) {
      hideModal()
      showLoader()
      return createOrder({ giftCardId: giftCard.id, denomination })
        .then((result) => {
          updateUserData(result?.updatedUser)

          result.orderId && navigate(`/order/${result.orderId}/success`)
        })
        .catch(handleError)
        .finally(hideLoader)
    }

    if (outOfAttempts) {
      navigate('/verification/result')
      return
    }

    setItem(LOCAL_STORAGE.GIFT_CARD_ID, `${giftCard.id}`)
    setItem(LOCAL_STORAGE.GIFT_CARD_DENOMINATION, `${denomination}`)
    navigate('/verification')
  }

  if (piggyBanksValue > user?.piggyBanks || pointsValue > user?.points) {
    return (
      <Modal
        showModal={showModal}
        hideModal={hideModal}
        text="Sorry, looks like you don’t have the requirements for that Gift Card yet. Keep earning!"
      />
    )
  }

  return (
    <Dialog open={showModal} onClose={hideModal} sx={styles.modal} maxWidth="xs">
      <DialogTitle variant="h1" sx={styles.title}>
        Confirm your order
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" className="confirmOrderModalText">
          Are you sure you want to purchase the {CURRENCY_SYMBOL[giftCard?.currency]}
          {denomination} {giftCard?.currency} {giftCard?.name} Gift Card for{' '}
          <span className="priceText pig">{formattedPiggiesValue}</span>{' '}
          <img src="/images/pig.png" />
          {' & '}
          <span className="priceText coin">{formattedPointsValue}</span>{' '}
          <img src="/images/coin.png" /> ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <BottomButton handleClick={hideModal} style={styles.closeButton}>
          Close
        </BottomButton>
        <BottomButton handleClick={handleConfirm}>Confirm</BottomButton>
      </DialogActions>
    </Dialog>
  )
}

const styles = {
  closeButton: {
    border: '2px solid rgb(251, 146, 60)',
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  modal: { '& .MuiDialog-paper': { borderRadius: '16px', margin: '21px' } },
  title: {
    textAlign: 'center',
  },
}

ConfirmOrderModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  showLoader: PropTypes.func.isRequired,
  giftCard: PropTypes.object.isRequired,
  denomination: PropTypes.string.isRequired,
}

export default ConfirmOrderModal
