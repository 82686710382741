import { useQuery } from 'react-query'
import { Box, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import * as campaignService from '../Services/campaignService'

const CampaignOfferEditor = ({ offerId, onNewOfferCreation }) => {
  const [jsonString, setJsonString] = useState('')

  let jsonObj = null
  try {
    jsonObj = JSON.parse(jsonString)
  } catch (e) {
    // We don't care about the errors here, usually temp edit errors
  }

  const convertRawDataToEditableFormat = (firestoreData) => {
    const apiData = { ...firestoreData }
    delete apiData.createdTimestamp
    delete apiData.updatedAt

    if (apiData.installRewards) {
      if (!apiData.installRewards.coins) {
        apiData.installRewards.coins = 0
      }
      if (!apiData.installRewards.piggyBanks) {
        apiData.installRewards.piggyBanks = 0
      }
    }

    return apiData
  }

  const getEditableSortedJsonStr = (editableObj) => {
    // Sort these important properties to the top, and make everything else alphabetical
    const keyOrder = [
      'id',
      'offerType',
      'isUAOffer',
      'isActive',
      'startTimestamp',
      'coinsPerDollar',
      'piggyPerDollar',
      'eligibleSpendAmount',
    ]
    for (const key of Object.keys(editableObj).sort()) {
      if (keyOrder.includes(key)) {
        continue
      }

      keyOrder.push(key)
    }
    const objWithSortedKeys = keyOrder.reduce((acc, key) => {
      acc[key] = editableObj[key]
      return acc
    }, {})
    return JSON.stringify(objWithSortedKeys, null, 2)
  }

  const getCampaignOffer = async () => {
    if (!offerId) {
      return null
    }

    const campaignOffer = await campaignService.getCampaignOffer(offerId)

    if (campaignOffer) {
      const editableObj = convertRawDataToEditableFormat(campaignOffer)
      const jsonString = getEditableSortedJsonStr(editableObj)
      setJsonString(jsonString)
    } else {
      setJsonString('')
    }

    return campaignOffer
  }
  const campaignOfferQuery = useQuery({
    queryKey: ['campaignOffer'],
    queryFn: getCampaignOffer,
    enabled: false,
  })

  useEffect(() => {
    campaignOfferQuery.refetch()
  }, [offerId])

  const canDelete = campaignOfferQuery.data && campaignOfferQuery.isFetched
  const canUpdate = jsonObj && canDelete
  const handleCreateCampaignOffer = async () => {
    if (!jsonObj) {
      toast.error("Invalid JSON format, can't create offer")
      return
    }

    try {
      const newOffer = await campaignService.createCampaignOffer(jsonObj)
      toast.success('Offer created successfully')

      if (onNewOfferCreation) {
        onNewOfferCreation(newOffer)
      }
    } catch (err) {
      console.error(err)
      toast.error('Failed to create offer')
      return
    }
  }

  const handleUpdateCampaignOffer = async () => {
    if (!jsonObj) {
      toast.error("Invalid JSON format, can't create offer")
      return
    }

    try {
      await campaignService.patchCampaignOffer(offerId, jsonObj)
      toast.success('Offer updated successfully')
      campaignOfferQuery.refetch()
    } catch (err) {
      console.error(err)
      toast.error('Failed to update offer')
      return
    }
  }

  const handleDeleteCampaignOffer = async () => {
    if (!offerId) {
      toast.error('Invalid offer ID')
      return
    }

    const isConfirmed = window.confirm('Are you sure you want to delete this offer?')
    if (!isConfirmed) {
      return
    }
    try {
      await campaignService.deleteCampaignOffer(offerId)
      toast.success('Offer deleted successfully')
      campaignOfferQuery.refetch()
    } catch (err) {
      console.error(err)
      toast.error('Failed to delete offer')
      return
    }
  }
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TextField
        label="JSON Data"
        variant="outlined"
        multiline
        minRows={10}
        maxRows={20}
        value={jsonString}
        onChange={(e) => setJsonString(e.target.value)}
        fullWidth
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '0.875rem',
            lineHeight: '1.4',
          },
        }}
        color={jsonObj === null ? 'error' : 'primary'}
      />
      <Box display="flex" gap={1}>
        <Button variant="contained" color="primary" onClick={handleCreateCampaignOffer}>
          Create Offer
        </Button>
        {canUpdate && (
          <Button variant="contained" color="secondary" onClick={handleUpdateCampaignOffer}>
            Update Offer
          </Button>
        )}
        {canDelete && (
          <Button variant="contained" color="error" onClick={handleDeleteCampaignOffer}>
            Delete Offer
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default CampaignOfferEditor
