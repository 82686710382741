import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { FreeMode, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import useCardsSlider from '../../../hooks/useCardsSlider'
import { BackArrow as ArrowIcon } from '../../../utils/icons'
import { sendGA4Event } from '../../../utils/utils'
import { ANALYTICS_EVENT } from '../../../constants'
import GiftCard from './GiftCard'

const GiftCardsRow = ({ giftCard }) => {
  const denominations = giftCard?.denominations

  const swiper = useCardsSlider(denominations?.length)

  const navigate = useNavigate()

  const handleSwiperClick = (swiper, { target }) => {
    const denomination = target.closest('.giftCardListCard')?.dataset?.denomination
    if (denomination) {
      sendGA4Event(ANALYTICS_EVENT.SELECT_ITEM.GIFT_CARD, {
        item_id: `${_.snakeCase(giftCard.name)}_${denomination}`,
        item_brand: giftCard.name,
        price: denomination,
      })

      navigate(`/rewards/${giftCard.id}/${denomination}`)
    }
  }

  const sliderSettings = {
    className: 'gamesListOffersRowSlider',
    freeMode: true,
    modules: [FreeMode, Navigation],
    spaceBetween: 20,
    slidesPerView: 1,
    navigation: true,
    width: 216,
    ref: swiper.positionRef,
    onSwiper: swiper.setSwiper,
    onClick: handleSwiperClick,
    onSlideChange: swiper.changeButtonStates,
  }

  if (!denominations?.length) {
    return null
  }

  return (
    <>
      <Typography variant="inherit" sx={styles.title}>
        {giftCard.name}
      </Typography>
      <div ref={swiper.wrapperRef} className="giftCardsRow">
        <div
          className={`gamesListOffersRowArrow ${!swiper.isPrevEnabled ? 'disabled' : ''} prev`}
          onClick={() => swiper.handleClickArrow(false)}
        >
          <ArrowIcon />
        </div>
        <Swiper {...sliderSettings}>
          {denominations.map((denomination, index) => (
            <SwiperSlide key={index}>
              <GiftCard giftCard={giftCard} denomination={denomination} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={`gamesListOffersRowArrow ${!swiper.isNextEnabled ? 'disabled' : ''} next`}
          onClick={swiper.handleClickArrow}
        >
          <ArrowIcon />
        </div>
      </div>
    </>
  )
}

const styles = {
  title: {
    marginBottom: '12px',
    paddingLeft: '20px',
  },
}

GiftCardsRow.propTypes = {
  giftCard: PropTypes.object.isRequired,
}

export default GiftCardsRow
