import CampaignEditor from './Components/CampaignEditor'

const Admin = () => {
  return (
    <>
      <CampaignEditor />
    </>
  )
}

export default Admin
